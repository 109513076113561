// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

import Swiper, { Autoplay, EffectFade, Navigation, Pagination } from "swiper";

const HERO = document.querySelector(".swiper-container--hero");

// init swiper
if (HERO && HERO.querySelectorAll(".swiper-slide").length > 1) {
    const HERO_SWIPER = new Swiper (HERO, {
        modules: [Autoplay, EffectFade, Navigation, Pagination],
        autoplay: {
            delay: 7000,
        },
        effect: "fade",
        loop: true,
        navigation: {
            nextEl: HERO.querySelector(".swiper-button--next"),
            prevEl: HERO.querySelector(".swiper-button--prev"),
        },
        pagination: {
            el: HERO.querySelector(".swiper-pagination"),
            clickable: true,
        },
        speed: 300,
    });

    HERO_SWIPER.on("slideChange", () => {
        HERO_SWIPER.slides.forEach(HERO_SLIDE => {
            console.log("video reset");
            const VIDEO = HERO_SLIDE.querySelector("video");
            VIDEO.pause();
            VIDEO.currentTime = 0;
        });

        HERO_SWIPER.slides[HERO_SWIPER.activeIndex].querySelector("video").play();
    });
}

const NEWS = document.querySelector(".swiper-container--news");

// init swiper
if (NEWS && NEWS.querySelectorAll(".swiper-slide").length > 1) {
    const BREAKPOINT = window.matchMedia("(max-width: 767px)");
    let news_swiper;

    const enableSwiper = function () {
        news_swiper = new Swiper (NEWS, {
            modules: [Autoplay, EffectFade, Navigation, Pagination],
            autoplay: {
                delay: 7500,
            },
            loop: true,
            navigation: {
                nextEl: NEWS.querySelector(".swiper-button--next"),
                prevEl: NEWS.querySelector(".swiper-button--prev"),
            },
            pagination: {
                el: NEWS.querySelector(".swiper-pagination"),
                clickable: true,
            },
            speed: 150,
            slidesPerView: 3,
            spaceBetween: 40,
        });
    };

    const breakpointChecker = function () {
        // if larger viewport and multi-row layout needed
        if ( BREAKPOINT.matches === true ) {
            // clean up old instances and inline styles when available
            if ( news_swiper !== undefined ) {
                news_swiper.destroy( true, true );
            }
            // or/and do nothing
            return;
        // else if a small viewport and single column layout needed
        } else if ( BREAKPOINT.matches === false ) {
            // fire small viewport version of swiper
            return enableSwiper();
        }
    };

    BREAKPOINT.addEventListener("change", breakpointChecker);

    breakpointChecker();
}
