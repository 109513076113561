// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

import handorgel from "handorgel";

const PARAMS     = new URLSearchParams(window.location.search);
const HANDORGELS = document.querySelectorAll(".handorgel");

HANDORGELS.forEach((element) => {
    const HANDORGEL = new handorgel(element);

    if (PARAMS.get("highlight")) {
        HANDORGEL.folds.forEach((fold) => {
            if (fold.content.querySelector("mark")) {
                fold.open();
            }
        });
    }

    /**
     * Fix Firefox bug
     */
    HANDORGEL.on("fold:close", (fold) => {
        setTimeout(() => {
            fold.header.classList.remove("handorgel__header--open");
            fold.content.classList.remove("handorgel__content--open");
        }, 100);
    });

    if (HANDORGEL.element.classList.contains("sitemap_wrapper")) {
        document.querySelectorAll(".open-sitemap").forEach((element) => {
            element.addEventListener("click", () => {
                HANDORGEL.folds[0].toggle();
            }, { passive: true });
        });
    }
});
